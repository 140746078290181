<template>
    <div class="ts-table">
        <ts-table-edit ref="table" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
                       @handleSort="sort"
                       @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
        </ts-table-edit>
    </div>
</template>

<script>
    import TsTableEdit from './../TsTableEdit'
    import commonMixin from './../commonMixin'

    export default {
        mixins: [commonMixin],
        components: {TsTableEdit},
        props: {
            api: {},
            cols: {},
        },
    }
</script>